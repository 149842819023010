@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		/* Shadcn */
		--background: 0, 0%, 100%;
		--foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--primary: 7 78% 59%;
		--primary-foreground: 0, 0%, 100%;

		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;

		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 210 40% 98%;

		--border: 240 5.9% 90%;
		/* --input: 214.3 31.8% 91.4%;
		--ring: 7 78% 59%; */
		--input: 240 5.9% 90%;
		--ring: 240 5.9% 10%;

		--radius: 0.5rem;
		/* Shadcn END */

		--black: 0 0% 0%;
		--gray: 0, 0%, 95%;
		--gray-500: 180, 2%, 61%;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-base text-foreground;
	}
	body::-webkit-scrollbar {
		background-color: hsl(var(--black));
		width: 0.75rem;
	}
	body::-webkit-scrollbar-track {
		background-color: hsl(var(--black));
	}
	body::-webkit-scrollbar-thumb {
		background-color: hsl(var(--primary));
	}
	body::-webkit-scrollbar-button {
		display: none;
	}
	a {
		@apply duration-300 ease-in-out hover:text-[#e85643];
	}
	h1,
	h2,
	h3 {
		@apply flex items-center text-balance font-readex font-bold  lg:flex-col lg:items-start;
	}

	h1,
	h2 {
		@apply text-[60px]/[72px] before:my-3 before:mr-12  before:min-h-[132px] before:w-3 before:skew-y-[45deg] before:self-stretch before:bg-primary 2xl:before:min-h-[80px] xl:before:min-h-[60px] lg:before:order-1 lg:before:my-0 lg:before:mr-0 lg:before:mt-3 lg:before:h-2 lg:before:min-h-3 lg:before:w-[120px] lg:before:skew-x-[45deg] lg:before:skew-y-[0] sm:before:min-h-1 xs:before:mt-1;
	}

	h1 {
		@apply lg:text-[54px]/[60px] md:text-[48px]/[58px] sm:text-[46px]/[52px] xs:text-[36px]/[40px];
	}
	h2 {
		@apply 2xl:text-[48px]/[60px] lg:text-[42px]/[48px] sm:text-[36px]/[40px] xs:text-[24px]/[36px];
	}
}

table.table {
	@apply mx-4 w-full border-collapse;
}
.table td {
	@apply border border-black p-2 text-sm;
}
.table th {
	@apply border border-black p-2;
}

.menu-item {
	@apply h-fit w-[48px] rotate-180 transform p-3 text-base font-normal leading-normal sm:w-fit sm:rotate-0 sm:p-0;
	writing-mode: vertical-rl;
}
@media (max-width: 768px) {
	.menu-item {
		writing-mode: unset;
	}
}
a.menu-item-link {
	@apply text-black hover:text-[#e85643];
}
a.menu-item-link.active {
	@apply text-[#e85643];
}
.inner {
	@apply relative z-1 flex min-h-[960px] flex-col flex-wrap p-[120px] text-lg 2xl:p-[80px] xl:min-h-[720px] xl:p-[60px] md:min-h-fit sm:p-[30px] xs:px-3 xs:text-base;
}
.desc:has(.text-vertical) {
	@apply flex flex-col flex-wrap gap-9 pl-[108px] 2xl:pl-[80px] xl:pl-[60px] lg:pl-0 sm:gap-6;
}
.text-vertical {
	@apply absolute bottom-[120px] left-[120px] rotate-[-180deg] text-center font-readex text-sm/9 font-medium uppercase tracking-[2.88px] text-gray-500 2xl:bottom-[80px] 2xl:left-[80px]  2xl:tracking-[2.2px] xl:bottom-[60px] xl:left-[40px] xl:tracking-[2px] lg:hidden;
	writing-mode: vertical-rl;
}
.activities .item {
	@apply flex gap-9 lg:gap-6 xs:gap-4;
}

.icon-wrap {
	@apply flex items-center justify-center rounded-lg bg-white;
}

ul.list {
	@apply grid grid-cols-1 pl-15  lg:pl-0;
}
ul.list li {
	@apply relative pl-7 before:absolute before:left-3 before:top-3 before:h-1 before:w-1 before:rounded-[50%] before:bg-black;
}
.page-agreement h2 {
	@apply mt-3 text-[48px]/[60px] before:min-h-fit sm:text-[24px]/[36px] xs:mt-1;
}
.page-content ul {
	@apply grid grid-cols-1 gap-2;
}
.page-content li {
	@apply relative pl-7 before:absolute before:left-3 before:top-3 before:h-1 before:w-1 before:rounded-[50%] before:bg-black;
}
